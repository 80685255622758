<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<!--div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Privacy Policy</li>
            </ul>
        </div>
    </div>
</div-->
<!-- End Page Title Area -->

<!-- Start Privacy Policy Area -->
<section class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy">
            <div class="title">
                <h2>NextCache Privacy Policy</h2>
                <p>Effective Date: 29-12-2021</p>
            </div>

            <div class="privacy-content">
                <h3>Information collection</h3>
                <p>Welcome to NextCache. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website "www.nextcache.in" or use our services.</p>
                            </div>

            <div class="privacy-content">
                <h3>1. Information We Collect</h3>
                <p>We may collect the following types of information:
                Personal Identification Information: Name, email address, phone number, and other contact details.
                    Technical Information: IP address, browser type, operating system, referring URLs, and browsing behavior.
                    Usage Data: Information about how you interact with our website, including pages visited, links clicked, and time spent on the site.
                    Transactional Data: Details of any transactions you may carry out through our website.</p>
                <ul>
                    <li>It has survived not only five centuries, but also the leap into electronic typesetting.</li>
                    <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                    <li>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</li>
                    <li>Various versions have evolved over the years, sometimes by accident sometimes on purpose.</li>
                </ul>
            </div>

            <div class="privacy-content">
                <h3>2. Personal data collected</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
            </div>

            <div class="privacy-content">
                <h3>3. 3. Personal data directly provided by a user</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
            </div>

            <div class="privacy-content">
                <h3>3. Protecting the personal data</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
            </div>

            <div class="privacy-content mb-0">
                <h3>4. Who has access to personal data</h3>
                <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Privacy Policy Area -->