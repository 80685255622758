<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<!--div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>
</div-->
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="main-contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="contact-title">
                            <h2>Write Us</h2>
                        </div>

                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email Address</label>
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                        <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Subject is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Message</label>
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="10" class="form-control"></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn btn-two" [class.disabled]="!contactForm.valid"><span>Send Message</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
                    <h3>Our contact details</h3>
                    <p>Our vertical solutions expertise can help your firm streamline operations and boost productivity. Whatever your sector, NextCache provides industry-compliant solutions that are suited to your needs.</p>

                    <ul class="address">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            <span>Address</span>
                            430, Omaxe Hazratganj, Gomti Nagar Ext., Lucknow Uttar Pradesh, India
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <span>Phone</span>
                            <a href="tel:+917408460084">+91 7408460084</a>
                            
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <span>Email</span>
                            <a href="mailto:info&#64;nextcache.in">info&#64;nextcache.in</a>
                            <a href="skype:info&#64;nextcache.in?chat">skype: info&#64;nextcache.in</a>
                        </li>
                    </ul>

                    <div class="sidebar-follow-us">
                        <h3>Follow us:</h3>
    
                        <ul class="social-icon">
                            <li><a target="_blank" href="https://www.facebook.com/nextcacheofficial"><i class="bx bxl-facebook"></i></a></li> 
                            <li><a target="_blank" href="https://www.instagram.com/nextcache_official/"><i class="bx bxl-instagram"></i></a></li>
                            <li><a target="_blank" href="https://www.linkedin.com/company/nextcache"><i class="bx bxl-linkedin-square"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-twitter"></i></a></li>
                                                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<!--div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96573.31103674119!2d-73.91097366523668!3d40.85176866829554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c28b553a697cb1%3A0x556e43a78ff15c77!2sThe%20Bronx%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1600202608808!5m2!1sen!2sbd"></iframe>
</div-->
<!-- End Map Area -->