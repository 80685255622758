<section class="about-us-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="about-title">
                        <span>About Us</span>
                        <h2>Managed IT Services Customized by Industries</h2>
                    </div>
                    <div class="tab">
                        <ul class="nav-tabset">
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                                <span (click)="switchTab($event, 'tab1')">
                                    Our Experience
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                                <span (click)="switchTab($event, 'tab2')">
                                    Why Us?
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                                <span (click)="switchTab($event, 'tab3')">
                                    Our Approach
                                </span>
                            </li>
                        </ul>
                        <div class="tabs-container">
                            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                                <p>We’re a team of dedicated individuals, forward-thinking thinkers, and the technology’s opposites! Leading the development and strategies for application development, as well as providing the necessary insights into the future strategy. Grow with us as we co-create revolutionary solutions in mobility, big data analytics, and the web for an ever-changing world of technology!</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Trusted Partner</li>
                                    <li><i class="bx bx-check-circle"></i> Product Security</li>
                                    <li><i class="bx bx-check-circle"></i> System Security</li>
                                    <li><i class="bx bx-check-circle"></i> Operational Security</li>
                                </ul>
                            </div>
                            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> System Security</li>
                                    <li><i class="bx bx-check-circle"></i> Operational Security</li>
                                    <li><i class="bx bx-check-circle"></i> Trusted Partner</li>
                                    <li><i class="bx bx-check-circle"></i> Product Security</li>
                                </ul>
                            </div>
                            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                                <p>Briefly introduce the context or problem you’re addressing. Describe the fundamental principles or values that guide your approach. This could include innovation, customer-centricity, sustainability, etc.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Discovery: Explain how you identify the needs or goals. This might involve research, stakeholder interviews, or data analysis.</li>
                                    <li><i class="bx bx-check-circle"></i> Planning: Outline how you develop a plan or strategy based on the discovery phase. This could involve setting objectives, timelines, and resources.</li>
                                    <li><i class="bx bx-check-circle"></i> Execution: Describe how you implement the plan. Highlight key activities, roles, and processes.</li>
                                    <li><i class="bx bx-check-circle"></i> Evaluation: Discuss how you measure success and assess outcomes. This could include metrics, feedback mechanisms, and review processes.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>