<section class="our-approach-area-three pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Approach</span>
            <h2>Boost Your Business with NextCache</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-key"></i>
                        </div>
                        <h3>IT Consulting</h3>
                        <p>We help organizations optimize their use of technology to meet their business goals. It can involve a range of services.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-padlock"></i>
                        </div>
                        <h3>Cloud Solutions</h3>
                        <p>We deliver computing services over the internet, include everything from storage & servers to databases & software applications.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-url"></i>
                        </div>
                        <h3>Marketing Solutions</h3>
                        <p> Encompass a range of strategies and tools designed to promote products or services, build brand awareness, and drive sales.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-antivirus"></i>
                        </div>
                        <h3>Security Solutions</h3>
                        <p>We Secure information, systems & networks from various threats & vulnerabilities. Our solutions are broadly categorized.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>