<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<!--div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>
</div-->
<!-- End Page Title Area -->

<!-- Start Safety Area -->
<section class="safety-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Cybersecurity services</span>
            <h2>You can protect your organization’s cybersecurity by services us</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-1.png" alt="Image">
                        <a routerLink="/services-details"><h3>Website Development</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            
            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-2.png" alt="Image">
                        <a routerLink="/services-details"><h3>Mobile Application Development</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-3.png" alt="Image">
                        <a routerLink="/services-details"><h3>Search Engine Optimization</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-4.png" alt="Image">
                        <a routerLink="/services-details"><h3>Social Media Marketing</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-5.png" alt="Image">
                        <a routerLink="/services-details"><h3>UI/UX Designing</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Content Marketing</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Email Marketing</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Content Marketing</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Cloud Computing</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Cloud Deployment</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Security Audit</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Cybersecurity Services</h3></a>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Safety Area -->