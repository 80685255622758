<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Right Sidebar</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Blog Right Sidebar</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-posts">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/blog-4.jpg" alt="Image">
                            </a>
                            <div class="single-blog-content">
                                <span>Security</span>
                                <h3><a routerLink="/blog-details">The essentials of cybersecurity solutions</a></h3>
                                <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat volutpat. consectetuer adipiscing elit</p>
                                <ul class="admin">
                                    <li><a routerLink="/blog-right-sidebar"><i class="bx bx-user-circle"></i> Posted by: Carl Bradshaw</a></li>
                                    <li class="float"><i class="bx bx-calendar-alt"></i> September 20, 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-posts">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/blog-5.jpg" alt="Image">
                            </a>
                            <div class="single-blog-content">
                                <span>Security</span>
                                <h3><a routerLink="/blog-details">Top 10 cybersecurity tips for working place</a></h3>
                                <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat volutpat. consectetuer adipiscing elit</p>
                                <ul class="admin">
                                    <li><a routerLink="/blog-right-sidebar"><i class="bx bx-user-circle"></i> Posted by: William Gordon</a></li>
                                    <li class="float"><i class="bx bx-calendar-alt"></i> September 19, 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-posts">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/blog-6.jpg" alt="Image">
                            </a>
                            <div class="single-blog-content">
                                <span>Security</span>
                                <h3><a routerLink="/blog-details">Cloud network detection and response</a></h3>
                                <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat volutpat. consectetuer adipiscing elit</p>
                                <ul class="admin">
                                    <li><a routerLink="/blog-right-sidebar"><i class="bx bx-user-circle"></i> Posted by: Brian Eortiz</a></li>
                                    <li class="float"><i class="bx bx-calendar-alt"></i> September 18, 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-posts">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/blog-7.jpg" alt="Image">
                            </a>
                            <div class="single-blog-content">
                                <span>Security</span>
                                <h3><a routerLink="/blog-details">Don’t forget cybersecurity on your computer</a></h3>
                                <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat volutpat. consectetuer adipiscing elit</p>
                                <ul class="admin">
                                    <li><a routerLink="/blog-right-sidebar"><i class="bx bx-user-circle"></i> Posted by: Daniel Brady</a></li>
                                    <li class="float"><i class="bx bx-calendar-alt"></i> September 17, 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-posts">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/blog-8.jpg" alt="Image">
                            </a>
                            <div class="single-blog-content">
                                <span>Security</span>
                                <h3><a routerLink="/blog-details">Top 5 cybersecurity threats in 2024</a></h3>
                                <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat volutpat. consectetuer adipiscing elit</p>
                                <ul class="admin">
                                    <li><a routerLink="/blog-right-sidebar"><i class="bx bx-user-circle"></i> Posted by: Bradshaw</a></li>
                                    <li class="float"><i class="bx bx-calendar-alt"></i> September 16, 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <span class="page-numbers current">1</span>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">2</a>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">3</a>
                            <a routerLink="/blog-right-sidebar" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget recent-post">
                        <h3 class="widget-title">Recent Post</h3>
                        
                        <ul>
                            <li>
                                <a routerLink="/blog-details">
                                    The essentiaaals of cybersecurity solutions
                                    <img src="assets/img/blog-details/recent-post-1.jpg" alt="Image">
                                </a>
                                <span>September 19,2024</span>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    Top 10 cybersecurity tips for working place
                                    <img src="assets/img/blog-details/recent-post-2.jpg" alt="Image">
                                </a>
                                <span>September 18,2024</span>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    Don’t forget caybersecurity on your computer
                                    <img src="assets/img/blog-details/recent-post-3.jpg" alt="Image">
                                </a>
                                <span>September 09,2024</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">Cyber Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Network Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Threat Hunter</a></li>
                            <li><a routerLink="/blog-right-sidebar">Incident Responder</a></li>
                            <li><a routerLink="/blog-right-sidebar">Secure Managed IT</a></li>
                            <li><a routerLink="/blog-right-sidebar">Server Monitoring</a></li>
                            <li><a routerLink="/blog-right-sidebar">Penetration Testing</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">August 2024</a></li>
                            <li><a routerLink="/blog-right-sidebar">June 2024</a></li>
                            <li><a routerLink="/blog-right-sidebar">April 2024</a></li>
                            <li><a routerLink="/blog-right-sidebar">January 2024</a></li>
                            <li><a routerLink="/blog-right-sidebar">December 2024</a></li>
                            <li><a routerLink="/blog-right-sidebar">November 2024</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags mb-0">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">Cyber</a></li>
                            <li><a routerLink="/blog-right-sidebar">Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Network</a></li>
                            <li><a routerLink="/blog-right-sidebar">Responder</a></li>
                            <li><a routerLink="/blog-right-sidebar">Secure</a></li>
                            <li><a routerLink="/blog-right-sidebar">Monitoring</a></li>
                            <li><a routerLink="/blog-right-sidebar">Penetration</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->