<section class="safety-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Enhance Your Business with NextCache’s Expertise</span>
                        <h3>Explore NextCache's Comprehensive IT & Marketing Services</h3>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-1.png" alt="Image">
                        <h3><a routerLink="/services-details">IT consulting</a></h3>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-2.png" alt="Image">
                        <h3><a routerLink="/services-details">Marketing Solutions</a></h3>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-3.png" alt="Image">
                        <h3><a routerLink="/services-details">Cloud Solutions</a></h3>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-4.png" alt="Image">
                        <h3><a routerLink="/services-details">Security Solutions</a></h3>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-5.png" alt="Image">
                        <h3><a routerLink="/services-details">Big Data & Analytics</a></h3>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <h3><a routerLink="/services-details">Quality Assurance</a></h3>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>