<div class="container">
    <div class="partner-slider">
        <owl-carousel-o [options]="partnerSlides">
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-1.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-2.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-3.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-4.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-5.png" alt="Image">
                    </a>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-6.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-7.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-8.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-9.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-10.png" alt="Image">
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>