<section class="feature-area feature-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-worldwide"></i>
                            <h3>Web Design & Development</h3>
                        </div>
                        <p>Focuse on the visual, experiential aspects that involves technical aspects of building a Website, includes both frontend & backend development.</p>
                        <div class="feature-shape">
                            <img src="assets/img/feature-shape.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-url"></i>
                            <h3>SEO & Digital Marketing</h3>
                        </div>
                        <p>The use of SEO and digital marketing techniques is crucial for raising online presence, generating traffic, and interacting with potential clients.</p>
                        <div class="feature-shape">
                            <img src="assets/img/feature-shape.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-cyber-security"></i>
                            <h3>Cyber Secuirty Services</h3>
                        </div>
                        <p>Essential for protecting organizations from a range of cyber threats and ensuring the safety and integrity of their information systems</p>
                        <div class="feature-shape">
                            <img src="assets/img/feature-shape.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>