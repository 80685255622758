<section class="banner-area banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <span class="top-title animate__animated animate__fadeInDown" data-wow-delay="1s">Differentiated. Delineated. Dedicated</span>
                            <h1 class="animate__animated animate__fadeInDown" data-wow-delay="1s">We're going to code. You'll unwind</h1>
                            <p class="animate__animated animate__fadeInUp" data-wow-delay="1s">We're your IT outsourcing Buddies ! </p>
                            <div class="banner-btn animate__animated animate__fadeInUp" data-wow-delay="1s">
                                <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img animate__animated animate__fadeInDown" data-wow-delay="1s">
                            <img src="assets/img/banner/banner-img.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>