<section class="about-us-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="about-title">
                        <span>About Us</span>
                        <h3>Through digital product engineering, we are experts in releasing value. For the purpose of providing client value more quickly, we foster an environment of constant innovation and improvement.</h3>
                    </div>
                    <div class="tab">
                        <ul class="nav-tabset">
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                                <span (click)="switchTab($event, 'tab1')">
                                    Our Experience
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                                <span (click)="switchTab($event, 'tab2')">
                                    Why Us?
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                                <span (click)="switchTab($event, 'tab3')">
                                    Our Approach
                                </span>
                            </li>
                        </ul>
                        <div class="tabs-container">
                            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                                <p>We take pride in our extensive portfolio, which includes 11+ successful projects across various sectors.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Client-Centric Success Stories</li>
                                    <li><i class="bx bx-check-circle"></i> Expertise That Sets Us Apart</li>
                                    <li><i class="bx bx-check-circle"></i> Industry Insights and Recognition</li>
                                    <li><i class="bx bx-check-circle"></i> Committed to Continuous Improvement</li>
                                </ul>
                            </div>
                            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                                <p>We’re a team of dedicated individuals, forward-thinking thinkers, and the technology’s opposites! Leading the development and strategies for application development, as well as providing the necessary insights into the future strategy</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Unique Value Proposition</li>
                                    <li><i class="bx bx-check-circle"></i> Track Record of Success</li>
                                    <li><i class="bx bx-check-circle"></i> Expertise and Experience</li>
                                    <li><i class="bx bx-check-circle"></i> Client-Centric Approach</li>
                                    <li><i class="bx bx-check-circle"></i> Innovation and Adaptability</li>
                                    <li><i class="bx bx-check-circle"></i> Results-Oriented Focus</li>
                                    <li><i class="bx bx-check-circle"></i> Values and Culture</li>
                                </ul>
                            </div>
                            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                                <p>Briefly introduce the context or problem you’re addressing. Describe the fundamental principles or values that guide your approach. This could include innovation, customer-centricity, sustainability, etc.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Discovery</li>
                                    <li><i class="bx bx-check-circle"></i> Planning</li>
                                    <li><i class="bx bx-check-circle"></i> Execution</li>
                                    <li><i class="bx bx-check-circle"></i> Evaluation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>