<section class="solution-area pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <div class="solution-title">
                        <span>Grow your Business with Us</span>
                        <h3>Elevate your business with our expert team. We offer tailored strategies to meet your needs and achieve your goals efficiently. Let's grow together!</h3>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">Gathering of Needs & Thought Investigation</a></h3>
                                    <p>WWe completely get your eagerness to kick off the development process in the best way possible. We're dedicated to transforming your ideas into effective solutions through thorough research.</p>
                                    <span>01</span>
                                </div>
                            </div>
                        </div>

                         <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">Tech Check & Agile Production</a></h3>
                                    <p>We carefully design a top-notch tech stack and implementation approach to carry out the project. Our process involves constructing the solution gradually, yielding dependable outcomes without any hassle.</p>
                                    <span>02</span>
                                </div>
                            </div>
                        </div>

                                                
                        <div class="col-lg-12 col-md-6 offset-md-3 offset-lg-0">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">Hosting & Technical Support</a></h3>
                                    <p>We are committed to ensuring a seamless experience post-launch by offering ongoing Hosting, Security Services and Support.</p>
                                    <span>03</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solution-img">
                    <img src="assets/img/solution-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>