<!-- Start Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Image"></a>
                    <p>We are a team of dedicated IT professionals with a diverse range of expertise, from software development and network security to cloud computing and data analytics.</p>
                    <ul class="social-icon">
                        <li><a target="_blank" href="https://www.facebook.com/nextcacheofficial"><i class="bx bxl-facebook"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/nextcache_official/"><i class="bx bxl-instagram"></i></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/nextcache"><i class="bx bxl-linkedin-square"></i></a></li>
                        <li><a target="_blank" href="#"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            430, Omaxe Hazratganj, Gomti Nagar Ext., Lucknow Uttar Pradesh, India
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:info&#64;nextcache.in">info&#64;nextcache.in</a>
                            <a href="skype:info&#64;nextcache.in?chat">skype: info&#64;nextcache.in</a>
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <a href="tel:+91-7408460084">+91 7408460084</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Solution</h3>

                    <ul class="import-link">
                        <li><a routerLink="/">Technical Support</a></li>
                        <li><a routerLink="/">Web Hosting & Servers</a></li>
                        <li><a routerLink="/">SEO & Digital Marketing</a></li>
                        <li><a routerLink="/">Web Design & Development</a></li>
                        <li><a routerLink="/">Server Hardening & Security Testing</a></li>
                        <li><a routerLink="/">Vulnerability Scan & Penetration Test</a></li>                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>

                    <ul class="import-link">
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">News</a></li>                                                
                        <li><a routerLink="/">Solutions</a></li>
                        <li><a routerLink="/">Strategies</a></li>                                             
                        <li><a routerLink="/">Case Studies</a></li>
                        <li><a routerLink="/">Security Advisories</a></li>                       
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p>© NextCache Private Limited <a href="https://www.nextcache.in/" target="_blank">NextCache</a></p>
            </div>

            <div class="col-lg-6 col-md-6">
                <ul class="footer-menu">
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Copy Right Area -->